
import { Component } from 'react';
import css from './styles_model3d.jsx';
import {jsx} from '@emotion/react';

import * as THREE from 'three';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import TWEEN from '@tweenjs/tween.js'
import {animData} from './animData.js';
import {Loader} from './loading.jsx';
import { connectLocation } from '../location/LocationConnectors';
import {If} from './utilities.jsx';

import MD from 'mobile-detect';
const MobileDetect = new MD(typeof(window) !== 'undefined' ? window.navigator.userAgent : '');

export const V2_FOLDER = 'v2';
export const pathWithVersion = path => {
  if (path.includes(`/${V2_FOLDER}/`)) return path;

  const pathParts = path.split('/');
  return [
    ...pathParts.slice(0, -2),
    ...[V2_FOLDER],
    ...pathParts.slice(-2)
  ].join('/');
}

var modelLoaded = false;
class XModel3D extends Component {
  componentDidMount() {
    fetch(MDMS_URL + '/api/v1/app_data/byr_application_config',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => response.json()).then(({ modelsPath, texturePath }) => {
      let currentStep = STEP_TO_ANIM[_.last(window.location.pathname.split('/'))] || 'default';

      texturePath = pathWithVersion(texturePath);
      modelsPath = pathWithVersion(modelsPath);
      apiInit({
        nodeId: 'bar',
        onProgress: null, // Unused
        onError: null, // Unused
        onDone: () => {
          modelLoaded = true;
          reloadModel(this.props.data, true);
        },
        modelsPath,
        texturePath,
        initStep: currentStep,
        suppressInitialAnimation: true,
        initialState: {
          'Underlayment': 'proarmor_new',
        }
      });
      apiAnimate();
    })
  }
  render() {
    const {t} = this.props;

    return (
      <div css={[css.model3D, this.props.modelCSS ? this.props.modelCSS : '']}>
        {this.props.loading && <Loader />}
        <canvas css={this.props.canvasCSS || css.modelCanvas} id="bar"></canvas>

      <If condition={!MobileDetect.mobile() && !this.props.disableControls}>
        <div className="model-instructions">
          <img src="https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,w_50/roofing/build-a-roof/drag-left.svg" alt="drag left" />
          <span><img src="https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,w_20/roofing/build-a-roof/icon-click-to-drag.svg" /> {t('build_your_roof.drag_to_rotate')}</span>
          <img src="https://imagecdn.owenscorning.com/ocimages/image/upload/q_auto,w_50/roofing/build-a-roof/drag-right.svg" alt="drag right" />
        </div>
      </If>
      </div>
    )
  }
}
export const Model3D = connectLocation(XModel3D);

export const reloadModel = async (data, animateFlag = false) => {
  if(!modelLoaded) {
    console.warn("Can't reload unloaded model")
    return;
  }
  currentVent = 'ridge-vent';

  for(var key in data) {
    if(!key.startsWith('selected_')) continue;

    let step = key.split('_')[1];

    if(step == 'shingle') {
      // This is causing textures to not update
      // Probably because of the valueTranslations variable
      await changeShingles(data[key][1]['filename']);
    } else {
      await updateModel(step, data[key]);
    }
  }

  if(animateFlag) {
    await animate();
    //await animateMeshes(CONFIG.initStep);
  }
}


export const updateModel = (step, value) => {
  //console.log('update start:' + step);
  const stepToChange = {
    'shingle': 'Shingles',
    'ice-water': 'IceWater',
    'underlayment': 'Underlayment',
    'hip-ridge': 'HipRidge',
    'ventilation': 'Ventilation'
  }

  const valueTranslations = {
    'proedge-flex-hip-ridge': 'proedge',
    'proedge-hip-ridge': 'proedge',
    'decoridge-hip-ridge': 'decoridge',
    'off_ridge_vents': 'box-vent',
    'ridge_vents': 'ridge-vent',
    'weatherlock-mat': 'mat-self-sealing',
    'weatherlock-g': 'g-self-sealing',
    'ventilation_not_sure': 'ridge-vent',
    'decoridge-duration-premium-cool': 'decoridge',
    'duraridge-hip-ridge': 'duraridge',
    'titanium-udl': 'titanium-udl30',
  }

  let slug = _.get(value, 'slug', undefined)
  let translatedValue = valueTranslations[slug] || valueTranslations[value] || slug || value;


  return apiSetTexture(stepToChange[step], translatedValue)
  //.then(() => console.log('update finish:' + step));
}
// Paste the model.js code here (without imports)
var camera, scene, controls, renderer, model, animFrameId, CONFIG;
var currentVent;
var isIdle = true;

function init() {
  return new Promise(function(resolve, reject) {
	  // CANVAS
	  var canvas = document.getElementById(CONFIG.nodeId);
	  canvas.width  = canvas.clientWidth/window.devicePixelRatio;
	  canvas.height = canvas.width * .9;

	  // CAMERA
	  camera = new THREE.PerspectiveCamera( 40, canvas.width/canvas.height, 1, 1000 );
	  camera.position.set( -111, 49, 156 );

	  // SCENE
	  scene = new THREE.Scene();
	  scene.active = true;
	  scene.position.set( 0, 10, 0 );

	  // RENDERER
	  renderer = new THREE.WebGLRenderer( { canvas: canvas, antialias: true, preserveDrawingBuffer: true } );
	  renderer.setClearColor( 0xffffff );
	  renderer.setPixelRatio( window.devicePixelRatio );

	  renderer.setViewport(0, 0, canvas.clientWidth/window.devicePixelRatio, canvas.clientHeight/window.devicePixelRatio);
	  renderer.sortObjects = true;
	  renderer.gammaOutput = true;
	  renderer.gammaFactor = 2.2;

	  // CONTROLS
	  controls = new OrbitControls(camera, canvas);
	  controls.maxPolarAngle = 1.0 * Math.PI / 2; // don't go below the ground
	  controls.minPolarAngle = 0.4 * Math.PI / 2; // don't go too high
	  controls.enablePan = false;
	  controls.enableZoom = true;
	  controls.minDistance = 125; // near
	  controls.maxDistance = 250; // far
	  controls.zoomSpeed = .08;
	  controls.enableDamping = true;
	  controls.dampingFactor = .1;
	  //controls.rotateSpeed = 0.20;
	  controls.addEventListener( 'change', render );
	  controls.addEventListener( 'end', readOut );


	  // LIGHTS
	  var dirLight = new THREE.PointLight();
	  dirLight.position.set( -.5, 1, 0 );
	  dirLight.position.multiplyScalar( 50 );
	  // dirLight gets attached to - and thus moves with - the camera
	  // (left and above relatively, per positioning)
	  camera.add( dirLight );
	  scene.add( camera );

	  // a soft white ambient light to keep the shading from being too dark
	  var light = new THREE.AmbientLight( 0xffffff, 0.4);
	  scene.add( light );

	  var gridHelper = new THREE.GridHelper( 400, 40, 0x0000ff, 0x808080 );
	  gridHelper.position.y = 0;
	  gridHelper.position.x = 0;
	  // scene.add( gridHelper );

	  // model

	  new MTLLoader()
		  .setPath( CONFIG.modelsPath )
		  .setResourcePath(CONFIG.texturePath)
		  .load( 'TPRS_v7.mtl?v=10', function ( materials ) {

			  materials.preload();

			  new OBJLoader()
				  .setMaterials( materials )
				  .setPath( CONFIG.modelsPath )
				  .load( 'TPRS_v7.obj?v=10', function ( object ) {

					  model = object;

					  model.scale.x = .1;
					  model.scale.y = .1;
					  model.scale.z = .1;

					  // run through the model and work over each mesh for interactivity, materials and such
            model.traverse( function ( mesh ) {
              if ( mesh instanceof THREE.Mesh ) {

                var loader = new THREE.TextureLoader();

                // set some common three.js attributes
                mesh.material.shininess = 0;
                mesh.material.side = THREE.DoubleSide;
                mesh.material.transparent = true;

                // set color space for image textures
                if ( mesh.material.map ) {
                  mesh.material.map.encoding = THREE.sRGBEncoding;
                  mesh.material.map.anisotropy = 16;
                }

                // adjust image maps based on the aspect ratio of the geometry and / or image map
                // and occaisionally other factors: slipping, scaling, flipping, alignment, etc.

                // note that this adjusts maps. so if two objects share a map, they will both be affected

                var split = mesh.name.split('_');
                var meshName = split.length ? split[0] : mesh.name;

                switch(meshName) {

                  case "lumber":
                    //mesh.renderOrder = 2
                    break;
                  case "deck":
                    //mesh.renderOrder = 3

                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.wrapS = THREE.RepeatWrapping;
                        mats[i].map.wrapT = THREE.RepeatWrapping;
                        mats[i].map.repeat.x = 3;
                        mats[i].map.repeat.y = 3;
                        mats[i].map.anisotropy = 16;
                        mats.needsUpdate = true;
                      }
                    }
                    break;
                  case "box-vent":
                    mesh.renderOrder = 100
                    mesh.material.opacity = 0;
                    mesh.visible = false;
                    break;
                  case "ridge-vent":
                    mesh.renderOrder = 10
                    break;
                  case "ridge":
                    mesh.renderOrder = 2
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].side = THREE.DoubleSide;
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = .502;
                        mats[i].map.repeat.y = 4;
                        mats[i].map.offset.x = -.002;
                        mats[i].map.offset.y = .5;
                      }
                    }
                    break;
                  case "shinglesback":
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 3;
                        mats[i].map.repeat.y = 1;
                        mats[i].map.offset.x = .5;
                      }
                    }
                    break;
                  case "shinglesfront":
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 2;
                        mats[i].map.repeat.y = 1;
                        mats[i].map.offset.x = .5;
                      }
                    }
                    break;

                  case "icewater":
                    //mesh.renderOrder = 20

                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 8;
                        mats[i].map.repeat.y = 1;
                      }
                    }
                    break;
                  case "icewaterback":
                    //mesh.renderOrder = 20

                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 16;
                        mats[i].map.repeat.y = 1;
                      }
                    }
                    break;
                  case "icewaterchimney":

                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 11;
                        mats[i].map.repeat.y = 1;
                      }
                    }
                    break;
                  case "icewatervalley":
                    //mesh.renderOrder = 200

                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 11;
                        mats[i].map.repeat.y = 1;
                      }
                    }
                    break;

                  case "underlayment":
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 8;
                        mats[i].map.repeat.y = 8.8;
                        mats[i].map.offset.x = 0;
                        mats[i].map.offset.y = .2;
                      }
                    }
                    break;
                  case "baselayer":
                    mesh.renderOrder = 100
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                      mats[i].transparent = true;
                      mats[i].opacity = 0;
                      //mats[i].visible = false;
                      if ( mats[i].map ) {
                        mats[i].map.encoding = THREE.sRGBEncoding;
                        mats[i].map.anisotropy = 16;
                        mats[i].map.repeat.x = 8;
                        mats[i].map.repeat.y = 8;
                        mats[i].map.offset.x = 0;
                        mats[i].map.offset.y = 0;
                      }
                    }
                    break;
                  case "starter":
                    mesh.material.map.repeat.x = 30;
                    mesh.material.map.repeat.y = 1;
                    break;
                  case "lumber":
                    var mats = mesh.material.length ? mesh.material : [mesh.material];
                    for ( var i = 0; i < mats.length; i++) {
                      mats[i].shininess = 0;
                    }
                    break;
                }

                // this is a good place to see all the mesh names
                //console.log(mesh.name);


              }
            });

					  scene.add( object );
            CONFIG.onDone();
            if(!CONFIG.supressInitialAnimation) {
					    animateMeshes(CONFIG.initStep);
            }
            resolve();
				  });

		  } );


	  window.addEventListener( 'resize', onWindowResize, false );
  });
}


function onWindowResize() {

	// camera.aspect = window.innerWidth / window.innerHeight;
	// camera.updateProjectionMatrix();
  //
	// renderer.setSize( window.innerWidth, window.innerHeight );

}

/////////

function animate() {
	// order matters!
  TWEEN.update();
	controls.update();
	animFrameId = requestAnimationFrame( animate );
	render();

}

function render() {

	renderer.render( scene, camera );

}

/////////
// FIX Promises!
function animateMeshes(state) {
	// condition for handling going 'under' the model in a certain case
	// gets reset when the camera tween finishes

  return new Promise(function(resolve, _reject) {
    if ( state == "edit-vent"){
      controls.maxPolarAngle = 1.4 * Math.PI / 2;
    }

    if (!animData.hasOwnProperty(state)) { resolve(); }

    model.traverse( function ( mesh ) {
      if ( mesh instanceof THREE.Mesh ) {

        var split = mesh.name.split('_');

        var meshName = split.length ? split[0] : mesh.name;

        var posY = animData[state][meshName];

        var tweenMesh = new TWEEN.Tween( mesh.position )
          .to( { x: 0, y: posY, z: 0 }, 1800 )
          .easing( TWEEN.Easing.Quadratic.InOut )
          .onStart( function() {
            isIdle = false;
          } )
          .onUpdate( function() {
            // custom
          } )
          .onComplete( function() {
            isIdle = true;
            cancelAnimationFrame( animFrameId );
          } )
          .start();


        var pos = animData[state]["camera"]

        var tweenCamera = new TWEEN.Tween( controls.object.position )
          .to( { x: pos[0], y: pos[1], z: pos[2] }, 1800 )
          .easing( TWEEN.Easing.Quadratic.InOut )
          .onStart( function() {
            isIdle = false;
          } )
          .onUpdate( function() {
            // custom
          } )
          .onComplete( function() {
            isIdle = true;
            cancelAnimationFrame( animFrameId );
            if ( state != "edit-vent"){
              controls.maxPolarAngle = 1.0 * Math.PI / 2;
            }
          } )
          .start();

        resolve();
      }
    });
  });
}

function fadeMeshes(state){
  if (!animData.hasOwnProperty(state)) { return }

  var meshes = animData[state]["in"];

  if ( meshes.length > 0) {
    for ( var i = 0; i < meshes.length; i++) {

      scene.traverse( function ( mesh ) {
        var split = mesh.name.split('_');
        var meshName = split.length ? split[0] : mesh.name;
        if (meshName == meshes[i]){
          if ( mesh && opacityChecker(mesh,0) ){
            fadeMesh(mesh,"in");
          }
        }
      });

    }
  }

  var meshes = animData[state]["out"];

  if ( meshes.length > 0) {
    for ( var i = 0; i < meshes.length; i++) {

      scene.traverse( function ( mesh ) {
        var split = mesh.name.split('_');
        var meshName = split.length ? split[0] : mesh.name;
        if (meshName == meshes[i]){
          if ( mesh && opacityChecker(mesh,1) ){
            fadeMesh(mesh,"out");
          }
        }
      });

    }
  }

}

function opacityChecker(mesh,op){

	var mats = mesh.material.length ? mesh.material : [mesh.material];

	for (var i = 0; i < mats.length; i++) {
		if (mats[i].opacity==op) {
			return true;
		}
	}

	return false;

}

function changeHipRidge(meshname){

  new Promise(function(resolve) {
	new MTLLoader()
		.setPath( CONFIG.modelsPath )
		.setResourcePath(CONFIG.texturePath)
		.load( meshname + '.mtl?v-10', function ( materials ) {

			materials.preload();

			new OBJLoader()
				.setMaterials( materials )
				.setPath( CONFIG.modelsPath )
				.load( meshname + '.obj?v-10', function ( object ) {

					object.scale.x = .1;
					object.scale.y = .1;
					object.scale.z = .1;

					// run through the model and work over each mesh for interactivity, materials and such
					object.traverse( function ( mesh ) {
						if ( mesh instanceof THREE.Mesh ) {

							var mats = mesh.material.length ? mesh.material : [mesh.material];

							// set some common three.js attributes
							mesh.material.shininess = 0;
							mesh.material.side = THREE.DoubleSide;
							mesh.material.transparent = true;

							// set color space for image textures
							if ( mesh.material.map ) {
								mesh.material.map.encoding = THREE.sRGBEncoding;
								mesh.material.map.anisotropy = 16;
							}

							// this is a good place to see all the mesh names
							// console.log(mesh.name);


						}
					});

          var oldRidge = scene.getObjectByName( "ridge_1" );
          var newRidge = object.getObjectByName( "ridge_1" );

          oldRidge.geometry = newRidge.geometry
          //oldRidge.material = newRidge.material

          var oldRidge = scene.getObjectByName( "ridge_2" );
          var newRidge = object.getObjectByName( "ridge_2" );

          oldRidge.geometry = newRidge.geometry
          //oldRidge.material = newRidge.material

          var oldRidge = scene.getObjectByName( "ridge_3" );
          var newRidge = object.getObjectByName( "ridge_3" );

          oldRidge.geometry = newRidge.geometry
          //oldRidge.material = newRidge.material

          resolve();
				});

		});
  });

}

function swapUnderlayment(meshname){

  new Promise(function(resolve) {
  new MTLLoader()
    .setPath(CONFIG.modelsPath)
    .setResourcePath(CONFIG.texturePath)
    .load( meshname + '.mtl?v=10', function ( materials ) {

      materials.preload();

      new OBJLoader()
        .setMaterials( materials )
        .setPath(CONFIG.modelsPath)
        .load( meshname + '.obj?v=10', function ( object ) {

          object.scale.x = .1;
          object.scale.y = .1;
          object.scale.z = .1;

          // run through the model and work over each mesh for interactivity, materials and such
          object.traverse( function ( mesh ) {
            if ( mesh instanceof THREE.Mesh ) {

              //var mats = mesh.material.length ? mesh.material : [mesh.material];

              // set some common three.js attributes
              mesh.material.shininess = 0;
              mesh.material.side = THREE.DoubleSide;
              mesh.material.transparent = true;

              // set color space for image textures
              if ( mesh.material.map ) {
                mesh.material.map.encoding = THREE.sRGBEncoding;
                mesh.material.map.anisotropy = 16;
              }

              // this is a good place to see all the mesh names
              // console.log(mesh.name);


            }
          });

          var mesh = scene.getObjectByName( "underlayment" );
          mesh.geometry = object.children[0].geometry

          // geometry is switched, now let's do the materials

          let filename = $("select.icewater").val();
          let underlaymentFilename = $("select.underlayment").val();
          let loader = new THREE.TextureLoader();

          scene.traverse(function (mesh) {
            let split = mesh.name.split('_');
            let meshName = split.length ? split[0] : mesh.name;
            let materials = getMaterials(mesh);

            if ( meshName=="underlayment" && meshname=="underlayment-b" ) {
              //2b
              materials.forEach(material => setClonedMapProperties(material, filename, loader, 16, 8));
            }

            if ( meshName=="underlayment" && meshname=="baselayer" ) {
              //materials.forEach(material => setClonedMapProperties(material, underlaymentFilename, loader, 8, 8));
            }

            if ( meshName=="underlayment" && meshname=="underlayment" ) {
              // 2
              materials.forEach(material => setClonedMapProperties(material, underlaymentFilename, loader, 8, 8.8));
            }

            if ( state == "edit-icewater-d" && meshName=="baselayer" ) {
              // 2d
              materials.forEach(material => setClonedMapProperties(material, underlaymentFilename, loader, 8, 3.9));
            }

            if ( state == "edit-icewater-c" && meshName=="baselayer" ) {
              // 2c
              materials.forEach(material => setClonedMapProperties(material, "new-product-temp", loader, 8, 8));
            }

          });


        });

      resolve();
    });
  });

}

// a function for helping us fade meshes
// reference: https://medium.com/@lachlantweedie/animation-in-three-js-using-tween-js-with-examples-c598a19b1263
function fadeMesh(mesh, direction, options) {
  if (!mesh) return;
  var originalRenderOrder = mesh.renderOrder;
  mesh.renderOrder = 1000;

  options = options || {};    // set and check
  var current = { percentage : direction == "out" ? 1 : 0 },
    // this check is used to work with normal and multi materials.
    mats = mesh.material.length ? mesh.material : [mesh.material],
    easing = options.easing || TWEEN.Easing.Linear.None,
    duration = options.duration || 1000;


  // tween opacity
  var tweenOpacity = new TWEEN.Tween(current)
    .to({ percentage: direction == "out" ? 0 : 1 }, duration)
    .easing(easing)
    .onStart( function() {
      direction == "in" ? mesh.visible=true : null
      isIdle = false;
    } )
    .onUpdate(function() {
      for (var i = 0; i < mats.length; i++) {
        mats[i].opacity = current.percentage;
      }
    })
    .onComplete(function(){
      if(options.callback){
        options.callback();
      }
      isIdle = true;
      direction == "out" ? mesh.visible=false : null
      mesh.renderOrder = originalRenderOrder;
      cancelAnimationFrame( animFrameId );
    });

  tweenOpacity.start();
  return tweenOpacity;
}

function getMaterials(mesh) {
  return Array.isArray(mesh.material) ? mesh.material : [mesh.material];
}

function setClonedMapProperties(material, filename, loader, repeatXFactor, repeatYFactor) {
  material.shininess = 0;
  material.transparent = true;
  if (material.map) {
    loader.load(
      CONFIG.texturePath + filename + '.jpg', function (texture) {
        let clonedTex = texture.clone();
        clonedTex.encoding = THREE.sRGBEncoding;
        clonedTex.wrapS = THREE.RepeatWrapping;
        clonedTex.wrapT = THREE.RepeatWrapping;
        clonedTex.anisotropy = 16;
        clonedTex.needsUpdate = true;
        clonedTex.repeat.x = (clonedTex.image.height / clonedTex.image.width) * repeatXFactor;
        clonedTex.repeat.y = repeatYFactor;
        clonedTex.offset.x = 0;
        clonedTex.offset.y = .2;

        let clonedMaterial = new THREE.MeshPhongMaterial({ map: clonedTex });
        material.map = clonedMaterial.map;
      }
    )
  }
}

function toggleItem(array, item) {
  let index = array.indexOf(item);
  if (index === -1) {
    array.push(item);
  } else {
    array.splice(index, 1);
  }
}

function removeItem(array, item) {
  let index = array.indexOf(item);
  if (index !== -1) {
    array.splice(index, 1);
  }
}

function addItem(array, item) {
  let index = array.indexOf(item);
  if (index === -1) {
    array.push(item);
  }
}

// use this function to get camera positions and / or targets
// called from CONTROLS on orbit end
function readOut() {
	// console.log( "POSITION: " + JSON.stringify(controls.object.position) );
	// console.log( "TARGET: " + JSON.stringify(controls.target) );
}


function attachHandlers() {
  $(document).ready(function(){

	  // reset
	  $('select').prop('selectedIndex',0);
	  $('input[type=checkbox]').removeAttr('checked');

	  // defaults
    $("select.colors").val("trudefinition-duration-driftwood").change();
	  $("select.icewater").val("mat-self-sealing").change();
	  $("select.underlayment").val("deck-defense").change();
	  $("select.ridge").val("duraridge").change();
	  $("select.starter").val("starter-strip").change();


	  $(".accordion").on("click", ".trigger", function() {

      if (isIdle){

        // the element we've clicked on
        var el = $(this);
        state = el.parent().attr('id');

        if (state == "edit-icewater-d"){

          $(this).addClass("selected");
          $("#edit-icewater .trigger").removeClass("selected");
          $("#edit-icewater-b .trigger").removeClass("selected");
          $("#edit-icewater-c .trigger").removeClass("selected");

          $("#edit-underlayment .trigger").addClass("disabled");
          var meshname = 'underlayment-b';
          swapUnderlayment(meshname);

          let animDataToChange = ["edit-shingles","edit-underlayment","edit-starter","edit-ridge","edit-vent","default"];
          for (let i = 0; i < animDataToChange.length; i++) {
            let ins = animData[animDataToChange[i]]["in"];
            let outs = animData[animDataToChange[i]]["out"];

            removeItem(ins, "icewater");
            addItem(outs, "icewater");
            removeItem(ins, "icewaterback");
            addItem(outs, "icewaterback");

            removeItem(ins, "icewatervalley");
            addItem(outs, "icewatervalley");
            removeItem(ins, "icewaterchimney");
            addItem(outs, "icewaterchimney");

            addItem(ins, "baselayer");
            removeItem(outs, "baselayer");

            removeItem(ins, "underlayment");
            addItem(outs, "underlayment");

            animData[animDataToChange[i]]["in"] = ins;
            animData[animDataToChange[i]]["out"] = outs;
          }

          // set the visibility f baselayer
          scene.traverse(function (mesh) {
            let split = mesh.name.split('_');
            let meshName = split.length ? split[0] : mesh.name;
            let materials = getMaterials(mesh);

            if (meshName=="underlayment") {
              mesh.visible = false;
            }
          });

        }

        if (state == "edit-icewater-c"){

          $(this).addClass("selected");
          $("#edit-icewater .trigger").removeClass("selected");
          $("#edit-icewater-b .trigger").removeClass("selected");
          $("#edit-icewater-d .trigger").removeClass("selected");


          $("#edit-underlayment .trigger").addClass("disabled");
          var meshname = 'underlayment-b';
          swapUnderlayment(meshname);

          let animDataToChange = ["edit-shingles","edit-underlayment","edit-starter","edit-ridge","edit-vent","default"];
          for (let i = 0; i < animDataToChange.length; i++) {
            let ins = animData[animDataToChange[i]]["in"];
            let outs = animData[animDataToChange[i]]["out"];

            removeItem(ins, "icewater");
            addItem(outs, "icewater");
            removeItem(ins, "icewaterback");
            addItem(outs, "icewaterback");

            addItem(ins, "icewatervalley");
            removeItem(outs, "icewatervalley");
            addItem(ins, "icewaterchimney");
            removeItem(outs, "icewaterchimney");

            addItem(ins, "baselayer");
            removeItem(outs, "baselayer");

            addItem(ins, "underlayment");
            removeItem(outs, "underlayment");

            animData[animDataToChange[i]]["in"] = ins;
            animData[animDataToChange[i]]["out"] = outs;
          }

        }

        if (state == "edit-icewater-b"){

          $(this).addClass("selected");
          $("#edit-icewater .trigger").removeClass("selected");
          $("#edit-icewater-c .trigger").removeClass("selected");
          $("#edit-icewater-d .trigger").removeClass("selected");

          $("#edit-underlayment .trigger").addClass("disabled");
          var meshname = 'underlayment-b';
          swapUnderlayment(meshname);

          let animDataToChange = ["edit-shingles","edit-underlayment","edit-starter","edit-ridge","edit-vent","default"];
          for (let i = 0; i < animDataToChange.length; i++) {
            let ins = animData[animDataToChange[i]]["in"];
            let outs = animData[animDataToChange[i]]["out"];

            removeItem(ins, "icewater");
            addItem(outs, "icewater");
            removeItem(ins, "icewaterback");
            addItem(outs, "icewaterback");

            addItem(ins, "icewatervalley");
            removeItem(outs, "icewatervalley");
            addItem(ins, "icewaterchimney");
            removeItem(outs, "icewaterchimney");

            removeItem(ins, "baselayer");
            addItem(outs, "baselayer");

            addItem(ins, "underlayment");
            removeItem(outs, "underlayment");

            animData[animDataToChange[i]]["in"] = ins;
            animData[animDataToChange[i]]["out"] = outs;
          }

        }

        if (state == "edit-icewater"){

          $(this).addClass("selected");
          $("#edit-icewater-b .trigger").removeClass("selected");
          $("#edit-icewater-c .trigger").removeClass("selected");
          $("#edit-icewater-d .trigger").removeClass("selected");

          $("#edit-underlayment .trigger").removeClass("disabled");
          var meshname = 'underlayment';
          swapUnderlayment(meshname);

          let animDataToChange = ["edit-shingles","edit-underlayment","edit-starter","edit-ridge","edit-vent","default"];
          for (let i = 0; i < animDataToChange.length; i++) {
            let ins = animData[animDataToChange[i]]["in"];
            let outs = animData[animDataToChange[i]]["out"];

            addItem(ins, "icewater");
            removeItem(outs, "icewater");
            addItem(ins, "icewaterback");
            removeItem(outs, "icewaterback");

            addItem(ins, "icewatervalley");
            removeItem(outs, "icewatervalley");
            addItem(ins, "icewaterchimney");
            removeItem(outs, "icewaterchimney");

            removeItem(ins, "baselayer");
            addItem(outs, "baselayer");

            addItem(ins, "underlayment");
            removeItem(outs, "underlayment");

            animData[animDataToChange[i]]["in"] = ins;
            animData[animDataToChange[i]]["out"] = outs;
          }

        }

        animateMeshes(state);
        fadeMeshes(state);

        // is this not active?
        if ( !el.hasClass("active") ){
          $('.trigger.active').toggleClass("active").next().slideToggle();
        }
        // make active open up
        el.toggleClass("active").next().slideToggle();

        // if no accordions are open, set scene to default state.
        if ($('.active').length==0) {
          animateMeshes(CONFIG.initStep);
          fadeMeshes(CONFIG.initStep);
        }

      }

	  });

	  $('select.shingles').on('change',function(){

		  var shingleline = $(this).val();

		  var $dropdown = $(".colors").empty();
		  $.each(colors[shingleline], function() {
    	  $dropdown.append($("<option />").val(this.uuid).text(this.name)).trigger('change');;
		  });


	  })

	  $('select.colors').on('change', function(){

		  var filename = $(this).val();
      changeShingles(filename);

	  })

	  $('select.icewater').on('change', function(){

		  var filename = $(this).val();
      changeIceWater(filename, state);
	  })

	  $('select.underlayment').on('change', function(){

		  var filename = $(this).val();
      changeUnderlayment(filename)

	  })

	  $('select.ridge').on('change', function(){

		  var meshname = $(this).val();
		  changeHipRidge(meshname);

	  })

	  $('select.exhaust').on('change', function(){
      var meshname = $(this).val();
      changeVentilation(meshname);


	  })

	  $('select.exhaust-colors').on('change', function(){

		  var color = $(this).val();
      changeVentilationColor(color);

	  })
  })
}

function changeShingles(filename) {

  // return false;

  var loader = new THREE.TextureLoader();

  //console.log(filename)

  scene.traverse( function ( mesh ) {
    var split = mesh.name.split('_');
    var meshName = split.length ? split[0] : mesh.name;

    if (meshName == "shinglesfront"){

      var mats = mesh.material.length ? mesh.material : [mesh.material];
      for ( var i = 0; i < mats.length; i++) {
        var mat = mats[i];
        mat.shininess = 0;
        mats.transparent = true;
        if ( mat.map ) {
            mat.map = loader.load(
              CONFIG.texturePath + filename + '.jpg',
              function(texture){
                mats.map = texture;
                mats.map.encoding = THREE.sRGBEncoding;
                mats.map.wrapS = THREE.RepeatWrapping;
                mats.map.wrapT = THREE.RepeatWrapping;
                mats.map.repeat.x = 2;
                mats.map.repeat.y = 1;
                mats.map.offset.x = .5;
                mats.map.anisotropy = 16;
                mats.needsUpdate = true;
              }
            )
        }
      }

    }

    if (meshName == "shinglesback"){

      var mats = mesh.material.length ? mesh.material : [mesh.material];
      for ( var i = 0; i < mats.length; i++) {
        var mat = mats[i];
        mat.shininess = 0;
        mats.transparent = true;
        if ( mat.map ) {
            mat.map = loader.load(
              CONFIG.texturePath + filename + '.jpg',
              function(texture){
                mats.map = texture;
                mats.map.encoding = THREE.sRGBEncoding;
                mats.map.wrapS = THREE.RepeatWrapping;
                mats.map.wrapT = THREE.RepeatWrapping;
                mats.map.repeat.x = 3;
                mats.map.repeat.y = 1;
                mats.map.offset.x = .5;
                mats.map.anisotropy = 16;
                mats.needsUpdate = true;
              }
            )
        }
      }

    }

    if (meshName == "ridge"){

      // 'tex/' + filename + '-ridge.jpg',

        loader.load(
          CONFIG.texturePath + filename + '-ridge.jpg',
          function(texture){
            mesh.material.map = texture;

            var mats = mesh.material.length ? mesh.material : [mesh.material];
            for ( var i = 0; i < mats.length; i++) {
              var mat = mats[i];
              mat.shininess = 0;
              mats.transparent = true;
              if ( mat.map ) {
                mat.map = loader.load(
                  CONFIG.texturePath + filename + '-ridge.jpg',
                  function(texture){
                    mats.map = texture;
                    mats.map.encoding = THREE.sRGBEncoding;
                    mats.map.wrapS = THREE.RepeatWrapping;
                    mats.map.wrapT = THREE.RepeatWrapping;
                    mats.map.anisotropy = 16;
                    mats.map.repeat.x = .502;
                    mats.map.repeat.y = 4;
                    mats.map.offset.x = -.002;
                    mats.map.offset.y = .5;
                    mats.needsUpdate = true;
                  }
                )
              }
            }
          }
        )
    }


  });



}

function changeIceWater(filename, state) {
  let loader = new THREE.TextureLoader();

  scene.traverse(function (mesh) {
    let split = mesh.name.split('_');
    let meshName = split.length ? split[0] : mesh.name;
    let materials = getMaterials(mesh);

    switch(meshName) {
      case "icewater":
        materials.forEach(material => setClonedMapProperties(material, filename, loader, 8, 1));
        break;
      case "icewaterback":
      case "icewatervalley":
        materials.forEach(material => setClonedMapProperties(material, filename, loader, 16, 1));
        break;
      case "underlayment":
        if ( state == "edit-icewater-b" || state == "edit-icewater-c" || state == "edit-icewater-d") {
          materials.forEach(material => setClonedMapProperties(material, filename, loader, 16, 8));
        }
        break;
      case "icewaterchimney":
        materials.forEach(material => setClonedMapProperties(material, filename, loader, 3, 1));
        break;
      default:
        break;
    }
  });
}

function changeUnderlayment(filename) {
  var loader = new THREE.TextureLoader();

  scene.traverse( function ( mesh ) {
    var split = mesh.name.split('_');
    var meshName = split.length ? split[0] : mesh.name;

    if (meshName == "underlayment" || meshName == "baselayer"){

      var mats = mesh.material.length ? mesh.material : [mesh.material];
      for ( var i = 0; i < mats.length; i++) {
        var mat = mats[i];
        mat.shininess = 0;
        mats.transparent = true;
        if ( mat.map ) {
          mat.map = loader.load(
            CONFIG.texturePath + filename + '.jpg',
            function(texture){
              mats.map = texture;
              mats.map.encoding = THREE.sRGBEncoding;
              mats.map.wrapS = THREE.RepeatWrapping;
              mats.map.wrapT = THREE.RepeatWrapping;
              mats.map.anisotropy = 16;
              mats.map.repeat.x = (mats.map.image.height / mats.map.image.width) * 8;
              mats.map.repeat.y = 8;
              mats.map.offset.x = 0;
              mats.map.offset.y = 0;
              mats.needsUpdate = true;

            }
          )
        }
      }


    }

  });

}

async function changeVentilation(meshname) {
  var venttype = $(this).find(':selected').data('venttype')

  let animDataToChange = ["edit-shingles","edit-ridge","edit-vent","default"];
  for (let i = 0; i < animDataToChange.length; i++) {
    let ins = animData[animDataToChange[i]]["in"];
    let outs = animData[animDataToChange[i]]["out"];

    if (meshname === "ridge-vent") {
      removeItem(ins, "box-vent");
      removeItem(outs, "box-vent");
      addItem(ins, "ridge-vent");
      addItem(outs, "ridge-vent");
    } else {
      addItem(ins, "box-vent");
      addItem(outs, "box-vent");
      removeItem(ins, "ridge-vent");
      removeItem(outs, "ridge-vent");
    }

    animData[animDataToChange[i]]["in"] = ins;
    animData[animDataToChange[i]]["out"] = outs;
  }

  var mesh = scene.getObjectByName( meshname );

  mesh.visible=true
  fadeMesh(mesh,"in");

  if (meshname === "box-vent"){

    const ridgeVentMesh = scene.getObjectByName( "ridge-vent" );
    fadeMesh(ridgeVentMesh,"out");

    // switch render order becuse ridge vent is absent
    scene.getObjectByName( "ridge-vent" ).renderOrder = 10
    //scene.getObjectByName( "ridge" ).renderOrder = 1


    for (var property in animData) {
      if (animData.hasOwnProperty(property)) {
        animData[property]["ridge"] = parseInt(animData[property]["ridge"] / 10) * 10 + 0.1;
      }
    }

  }

  const boxVentMesh = scene.getObjectByName( "box-vent" );
  if (meshname === "ridge-vent" && boxVentMesh?.visible) {
    fadeMesh(boxVentMesh,"out");

    // default render order
    scene.getObjectByName( "ridge-vent" ).renderOrder = 1
    //scene.getObjectByName( "ridge" ).renderOrder = 2

    for (var property in animData) {
      if (animData.hasOwnProperty(property)) {
        const ridgeProperty = parseInt(animData[property]["ridge"] / 10) * 10;
        animData[property]["ridge"] = ridgeProperty;
      }
    }
  }

  //console.log(animData)

}


// Not used right now
function changeVentilationColor(color) {

  var color = $(this).val();
  var loader = new THREE.TextureLoader();
  var mesh = scene.getObjectByName( "box-vent" );

  loader.load(
    CONFIG.texturePath + 'box-vent-' + color + '.jpg',
    function(texture){
      mesh.material.map = texture;
      mesh.material.map.encoding = THREE.sRGBEncoding;
      mesh.material.map.wrapS = THREE.RepeatWrapping;
      mesh.material.map.wrapT = THREE.RepeatWrapping;
      mesh.material.map.repeat.x = 1;
      mesh.material.map.repeat.y = 1;
      mesh.material.needsUpdate = true;
    }
  )

}





// API Starts here - See api-contract.md
window.model3d = {
  raw: {init: init, animate: animate, attachHandlers: attachHandlers},
  api: {init: apiInit, animate: apiAnimate, setTexture: apiSetTexture, changeStep: apiChangeStep}
}

var DEFAULT_CONFIG = {
  nodeId: 'threejs',
  onProgress: null, // Unused
  onError: null, // Unused
  onDone: function() { $( "#loading" ).hide(); },
  modelsPath: 'models/',
  texturePath: 'tex/',
  initStep: 'default',
  suppressInitialAnimation: false,
  initialState: {
    'Underlayment': 'proarmor'
  }
}




function apiInit(config = DEFAULT_CONFIG) {
  CONFIG = config;
}
async function apiAnimate() {
  await init();

  for(var element in CONFIG.initialState) {
    await apiSetTexture(element, CONFIG.initialState[element])
  }

  //animate();

}


async function apiSetTexture(layer, texture) {
  if(!(CONFIG && layer && texture && texture !== -1)) {
    return;
  }

  return eval('change' + layer)(texture);
}

export async function apiChangeStep(step) {

  if(!model)
    return;

  var anim = STEP_TO_ANIM[step] || 'default'

  animateMeshes(anim);
  fadeMeshes(anim);
}

const STEP_TO_ANIM = {
  'splash': 'default',
  'shingles': 'edit-shingles',
  'ice-water': 'edit-icewater',
  'underlayment': 'edit-underlayment',
  'starter-shingles': 'edit-starter',
  'hip-ridge': 'edit-ridge',
  'ventilation': 'edit-vent',
  'summary': 'default',
  'default': 'default',
}
