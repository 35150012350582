import { ui, select, when, s, transform, always } from '@owenscorning/pcb.alpha';
import { makeT, stringsFor } from "../../../../location/locales";
import Modular from '../Modular';
import Section from '../../Section';
import _ from 'lodash';

// Assets
import IconPimLink from '../../../../ComponentLibrary/icons/icon-pim-link';

const defaultCopy = {
  prehead: '',
  heading: '',
  bodyCopy: null
}

const pimConnectedTypes = ["Product::Insulation", "Product::Paroc"];

const sectionDefinition = (UI, index, title, unmovables, modules, opts) =>
  ui`List/Item`.of({
    modules: Modular.Module.List(UI, unmovables, modules, Modular.Module, true, opts)
  })({
    title,
    hideable: true,
    standalone: true,
    tools: ui`Dots`({
      actions: [
        {
          label: 'Settings',
          icon: 'cogs',
          onClick: () => Modular.Section(index)
        }
      ]
    })
  })

const setItemOpener = (key, opener) => {
  if(Board[key] !== opener){
    setTimeout( () => {
      Board.Set({[key]: opener})
    }, 3000)
  }
}

const overrideHeroLayout = () => {
  return {
    layout: {
      Header: {
        Hero: getDefaultHero()
      }
    }
  };
}

const getDefaultHero = () => {
  return {
    preset: 'custom',
    type: 'tier_3',
    showHeroImage: 'small',
    contentOrder: {
      desktop: 'first',
      mobile: 'first',
    }
  }
}

const reducedBannerDefaults = ( language, productsPath) => {
  const t = makeT(language);
  return {
    title: t('product.reduced_banner.title'),
    message: {
      ops: [
        {
          insert: t('product.reduced_banner.message'),
        },
        {
          insert: t('product.reduced_banner.explore_other_products'),
          attributes: {
            link: `/${language}${productsPath}`,
          }
        },
        {
          "insert": "\n"
        },
      ]
    },
  }
}

const sectionTemplate = (name, modules = [], showInSticky, background, showHeading, translationKey, build, hidden = false) => {
  const translatedName = translationKey ? _.get(stringsFor(build.language), translationKey) : name;

  return _.merge({},
    {
      __hidden: hidden,
      details: {
        ...Section.Defaults.details,
        name: translatedName,
        ...(background ? { background } : {}),
        ...(showHeading ? {
          useNameAsHeading: true,
          initialHeadingLevel: 'h2',
          forceHeadingHierarchy: true
        } : {})
      },
      sticky: { name: translatedName, show: (showInSticky == null || showInSticky) }
    },
    _.isEmpty(modules) ? {} : {
      modules: _.mapValues(modules, module => _.merge({}, {
          templated: true,
        },
        _.isString(module) ? {
          type: module
        } : module))
    }
  )
}

const Product = (type, attributes, sectionDefaults, stickyNavCta, overrides, modules, productsPath, defaultHero = false, zoneCategory = null, initData = {}) => Modular(
  type, 'Product',
  _.merge({},
    {
      // custom things for Products here
      read: ({ edit = false, build, contents, metadata, zipFile }) => {
        modules = _.isFunction(modules) ? modules(build) : modules;
        initData = _.isFunction(initData) ? initData(build) : initData;
        if (_.isEmpty(contents) && edit) {
          let finalMetadata = _.cloneDeep(metadata);
          let { sections, layout = {}, ...rest } = contents;

          if (!_.isEmpty(initData)) {
            layout = _.isEmpty(layout) ? initData.layout : layout;
            if (!metadata.settings && initData?.metadata?.settings) {
              finalMetadata.settings = initData.metadata.settings;
            } else if (!finalMetadata.settings) {
              finalMetadata.settings = {};
            }
          }

          const availabilityBlock = {
            availability: {
              status: "active",
              visibility: "listed",
              reducedBanner: reducedBannerDefaults(build?.language || 'en', productsPath)
            }
          };

          finalMetadata.settings = _.merge(finalMetadata.settings, availabilityBlock);
          const moduleOverrides = _.keyBy(_.isFunction(sectionDefaults) ? sectionDefaults(build) : sectionDefaults, module => module.title)
          const keyedModules = _.keyBy(modules, module => module.title)
          const modulesWithOverrides = _.values(_.merge({}, keyedModules, moduleOverrides));

          // convert arrays [first, second, ...] to objects { 0: first, 1: second, ... } as those are mergeable by key
          const sectionsAsObject = _.mapValues(sections, (section) => ({
            ...section,
            modules: _.mapValues(section.modules || [])
          }))
          const templateSectionsAsObject =
            _.mapValues(modulesWithOverrides,
              ({ title, initial, showInSticky, background, showHeading, translationKey, hidden }, index) => {
                if (index === '4' && !_.isEmpty((contents?.sections?.[4]?.modules || []).filter(module => module.type === 'ApplicableStandards'))) {
                  initial = undefined;
                }
                return sectionTemplate(title, initial, showInSticky, background, showHeading, translationKey, build, hidden)
              })
          const newSectionsAsObject = _.mergeWith({}, sectionsAsObject, templateSectionsAsObject, (objValue, srcValue, key) => {
            if (key === 'modules' || key === 'details') {
              return _.isEmpty(objValue) ? srcValue : objValue
            }
          });

          const newSectionsWithArrays = _.values(newSectionsAsObject).map(section => ({
            ...section,
            modules: _.values(section.modules || {})
          }))

          return {
            contents: {
              layout: _.merge({},
                {
                  Header: {
                    Hero: defaultHero ? getDefaultHero() : {},
                    StickyNav: {
                      cta: _.isFunction(stickyNavCta) ? stickyNavCta(build) : stickyNavCta,
                    }
                  },
                },
                layout
              ),
              sections: newSectionsWithArrays,
              ...rest,
            },
            metadata: finalMetadata
          }
        } else {
          return {
            contents: _.merge(
              {},
              ...contents,
              defaultHero ? overrideHeroLayout() : {},
            ),
            metadata,
            zipFile
          }
        }
      },
      write: ({ contents, metadata }) => {
        return {
          contents,
          metadata
        }
      },
      list: (UI) => ({
        'layout/Header': Modular.Layout.List(UI, 'Header', ['StickyNav']),
        sections: _.mapValues(_.isFunction(modules) ? modules(Modular.Build) : modules,
          ({ title, unmovables, modules, opts }, index) => sectionDefinition(UI, index, title, unmovables, modules, opts)),
      }),
      selection: {
        in: (selection) => selection === 'layout/Header/Hero'
          ? { ...Modular.Selection.in(), sidebar: 'settings' }
          : { ...Modular.Selection.in(selection), sidebar: 'builder' },
        out: (focus) => Board.sidebar === 'settings'
          ? 'layout/Header/Hero'
          : Modular.Selection.out(focus)
      },
      settings: {
        pimConnection: ui`List/Item`.of(
          ui`PimConnection`({
            label: "PIM Connection",
            pimSource: `pim_${type.split("::")[1]?.toLowerCase()}`
          })
        )({
          title: "PIM Connection",
          standalone: true,
          unwrapped: true,
          visible: pimConnectedTypes.includes(type)
        }),
        meta: ui`List/Item`.of(
          // meta_title.value, meta_description.value, meta_keywords.value on old PDP
          ui`Product/Meta`({ label: 'Roofing PDP Metadata' })
        )({
          title: 'Metadata',
          unwrapped: true,
          standalone: true
        }),
        general: ui`List/Item`.of(
          ui`Form`.of({
            // product_type on old PDP?
            prehead: ui`Text`({
              label: 'Prehead',
              placeholder: 'e.g. “VentSure®”',
              disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
              tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
              tipIcon: IconPimLink
            }),
            // proper_name on old PDP
            proper_name: ui`Text`({
              label: 'Product Name (Proper)',
              placeholder: 'e.g. “4-Foot Strip”',
              disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc"),
              tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc").then("Field connected to PIM product."),
              tipIcon: IconPimLink
            }),
            [s._]: ui`Tip`.of('Product Name will be used as the H1 on the PDP, as well as in product lists / filtering.'),
            // not on old PDP? tagline maybe?
            subheading: ui`Text`({
              label: 'Product Line 2 / Subheading',
              placeholder: 'e.g. “Heat and Moisture Ridge Vent”',
              disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
              tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
              tipIcon: IconPimLink
            }),
            [s._]: ui`Tip`.of('Optionally, the Product Line 2 will be used on the PDP to supplement the main product name. This will not be used in lists / filtering.'),
            // teaser_copy.value on old PDP
            body_copy: ui`Text/Rich`({
              label: 'Marketing Body Copy',
              visible: type === 'Product::Paroc',
              disabled: when`~linked_object`.is.present,
              tip: when`~linked_object`.is.present.then("Field connected to PIM product."),
              tipIcon: IconPimLink
            }),
            short_description: ui`Text`({
              label: 'Product Short Description',
              multiline: true
            }),
            [s._]: ui`Tip`.of('Marketing copy that gives a brief overview of the product that could be shown in multiple places across the website.'),
            long_description: ui`Text`({
              label: 'Product Long Description',
              multiline: true,
              maxlength: 250,
              disabled: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc"),
              tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.equal.to("pim_paroc").then("Field connected to PIM product."),
              tipIcon: IconPimLink
            }),
            [s._]: ui`Tip`.of('Longer Description that is generally used when a product is shown in a list view.'),
          })
        )({
          title: 'Name & Description',
          unwrapped: true,
          standalone: true
        }),
        availability: ui`List/Item`.of(
          ui`Form`.of(
            {
              // discontinued (Boolean) or legacy_link.value (for external) on old PDP
              status: ui`Choices`.of({
                active: 'Active',
                reduced: "Reduced",
                discontinued: 'Discontinued',
                external: 'External'
              })({
                label: 'Status',
                mode: ui`Choices/Mode/Dropdown`,
                default: 'active'
              }),
              [s._]: ui`Tip`.of(
                '<strong>Active</strong> means this product is available normally, and will show in appropriate filters/lists.<br>\n' +
                '<strong>Reduced</strong> means the product may have limited availability or may be temporarily unavailable.<br>\n' +
                '<strong>Discontinued</strong> means the PDP will be hidden filters/lists, and the PDP will have a banner on the page indicating to customers that the product has been discontinued.<br>\n' +
                '<strong>External</strong> means the user will be taken to an external URL instead of a product detail page (intended to be used during transition to new site)'
              ),
              reducedBanner: ui`Form`.of({
                title: ui`Text`({
                  label: "Title",
                }),
                message: ui`Text/Rich`({
                  label: "Message Content",
                }),
                dismissable: ui`Switch`({
                  label: "Dismissable",
                  default: false,
                })
              })({
                label: 'Reduced Availability Banner',
                visible: when`../status`.is.equal.to('reduced')
              }),
              // legacy_link.value on old PDP
              externalUrl: ui`Text`({
                label: 'External URL',
                visible: when`../status`.is.equal.to('external')
              }),
              // unlisted (Boolean) on old PDP
              visibility: ui`Choices`.of({
                listed: 'Listed',
                unlisted: 'Unlisted'
              })({
                label: 'PDP Visibility',
                mode: ui`Choices/Mode/Dropdown`,
                default: 'listed'
              }),
              [s._]: ui`Tip`.of('<strong>Listed</strong> means the PDP will show in appropriate filters/lists.<br>\n' +
                '<strong>Unlisted</strong> means the PDP will be hidden from any filters/lists and instead will need to be manually linked to for discoverability.'),
              [s._]: ui`Build/Language`({
                language: select`~build/locale_name`,
                label: 'Language / Location'
              }),
              ...(zoneCategory ? {
                // covered_zones on old PDP
                zones: ui`Choices`.of(MDMS_URL + '/api/v1/product/zones?as=choices')({
                  query: {
                    country_code: select`~build/region`,
                    category: zoneCategory
                  },
                  mode: ui`Choices/Mode/Vertical`,
                  format: ui`Choices/Format/Array`,
                  multiple: true,
                  label: 'PSA / Zone Availability'
                })
              } : {})
            }
          )
        )({
          title: 'Availability & Visibility',
          unwrapped: true,
          standalone: true
        }),
        attributes: ui`List/Item`.of(
          attributes
        )({
          title: 'Attributes',
          unwrapped: true,
          standalone: true
        }),
        documents: ui`List/Item`.of(
          ui`Form`.of({
            categories: ui`List`.of({
              category: ui`Choices`.of(`${MDMS_API_CDN_HOST_AND_PROTOCOL}/api/v2/cms/sites/${PB_SITE}/published`)({
                responseHandler: data => {
                  let items =  data?.data?.[0]?.contents?.items || []
                  return items;
                },
                query: {
                  filter: {
                    type: 'Cms::Content::Attribute',
                    language_iso_code: select`~build/language`,
                    name: 'PDP Document Categories',
                    published: true,
                  },
                  fields: {
                    '*': 'contents'
                  },
                  page: {
                    number: 1,
                    size: 1,
                  }
                },
                label: 'Category',
                mode: ui`Choices/Mode/Dropdown`
              }),
              documents: ui`List`.of(
                ui`Data/Parameters`.of('SpecificationsAndLiterature')({ dataset: PB_SITE === 'www.owenscorning.com' ? 'documents' : 'document_builder', unwrapped: true })
              )({
                label: 'Documents',
                singular: 'Document',
                title: 'title',
                immutable: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_"),
                visible: when`../category`.isnt.equal.to('safety-data-sheets'),
                tip: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_").then("Field connected to PIM product."),
                tipIcon: IconPimLink
              }),
              sds_search: ui`Form`.of({
                [s._]: ui`Tip`.of('Enter a search term for creating a link to the SDS site with the query for that term, e.g. enter “foamular” for creating a link as the following: https://www.owenscorning.com/en-us/sds/results?q=foamular'),
                term: ui`Text`({
                  placeholder: 'e.g. Foamular'
                })
              })({
                label: 'SDS Search Term',
                visible: when`../category`.is.equal.to('safety-data-sheets')
              })
            })({
              title: 'category',
              singular: 'Category',
              immutable: when`~linked_object`.is.present.and.when`~linked_object/source`.is.containing("pim_")
            }),
            copy: ui`Content/Basic`({
              default: defaultCopy
            }),
            featured_documents: ui`Form`.of({
              settings: ui`Form`.of({
                itemsPerRow: ui`Choices`.of({
                  '1': '1',
                  '2': '2',
                  '3': '3'
                })({
                  label: 'Number of items per row (at desktop)',
                  default: '2'
                }),
                [s._]: ui`Tip`.of('Tablet and Mobile are automatically defined with 1 item per row.')
              })({
                label: 'Featured Documents Layout'
              })
            }),
          })
        )({
          title: 'Documents',
          unwrapped: true,
          standalone: true,
          id: "productDocuments",
          setOpenController: (setOpen) => setItemOpener('setDocumentsOpener', setOpen),
        }),
        images: ui`List/Item`.of(
          ui`Form`.of({
            [s._]: ui`Tip`.of('Upload images below, and assign them for use with the dropdowns.'),
            images: ui`List`.of(ui`Image`({ crop: true })),
            hero_image: ui`Choices`.of([])({
              contents: transform`../images`(images => (images || []).map((image, index) => ({ label: `Product Image ${index + 1}`, value: index }))),
              label: 'Hero Image',
              mode: ui`Choices/Mode/Dropdown`
            }),
            list_item_image: ui`Choices`.of([])({
              contents: transform`../images`(images => (images || []).map((image, index) => ({ label: `Product Image ${index + 1}`, value: index }))),
              label: 'List Item Image',
              mode: ui`Choices/Mode/Dropdown`
            })
          })
        )({
          title: 'Images',
          unwrapped: true,
          standalone: true
        }),
        associatedProducts: ui`List/Item`.of(
          ui`Data/Parameters`.of('ProductsInSolution')({
            dataset: 'products_insulation',
            unwrapped: true,
          })
        )({
          title: 'Products In This Solution',
          id: "associatedProducts",
          setOpenController: (setOpen) => setItemOpener('associatedProductOpener', setOpen),
          unwrapped: true,
          standalone: true,
          visible: type === 'Product::Enclosure',
        })
      },
      layout: { Header: ['Hero', 'StickyNav'] },
      view: (UI, layout) => Modular.Renderer(UI, layout, { section: { selectable: false } })
    },
    overrides
  )
)

export default Product;

